import React, { useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Popup from "reactjs-popup";
import { SpinnerDotted } from 'spinners-react';
import { toast } from 'react-toast';

import { getContract } from '../Utils/GetContract';
import Web3Ctx from '../Context/Web3Ctx';
import config from '../../config'

import Navigation from '../Navigation/Navigation';
import FooterComponent from '../Footer/FooterComponent';
import ImageView from '../common/ImageView';
import NftCardV2 from '../common/NftCardV2';
import useContainerDimensions from '../common/useContainerDimensions';

import './ForgePage.css';
import ecLogo from '../../assets/images/logo-in-menu.png';
import icClose from '../../assets/images/close.png';
import icDnd from '../../assets/images/dnd.png';
import icInfo from '../../assets/images/info.png';
import { getTokenURI, getLayersURI } from '../Utils';

//TRAIT ICONS
import ogLayerDrop from '../../assets/images/traits/icon-og-layer-drop.png';
import alphaLayerDrop from '../../assets/images/traits/icon-alpha-layer-drop.png';
import limitedSlot from '../../assets/images/traits/icon-limitedtraitslot.png';
import battlePunk from '../../assets/images/traits/icon-battle-royal-punk.png';
import battleRoyalty from '../../assets/images/traits/icon-royalty-battle.png';
import supporter from '../../assets/images/traits/icon-supporter.png';
import closeX from '../../assets/images/nft-icons/close_button.svg';
import PLACEHOLDER from '../../assets/images/traits/small_rplaceholder_icon.png';

const CardTraits = ({ state, tokenMetadata, handleSelectCardTrait }) => {
    const [traitsOfToken, setTraitsOfToken] = useState([]);
    const [selectedTraitIdx, setSelectedTraitIdx] = useState(null);
    const [selectedTraitIdxDetails, setSelectedTraitIdxDetails] = useState(0);

    const containerRef = useRef()
    let width = useContainerDimensions(containerRef).width;

    //TRAIT
    const traitImages = {
        0: ogLayerDrop,
        1: alphaLayerDrop,
        2: limitedSlot,
        3: battlePunk,
        4: battleRoyalty,
        5: supporter
    }

    useEffect(() => {
        console.info(tokenMetadata)
        if (tokenMetadata && tokenMetadata.traits) {
            setTraitsOfToken(tokenMetadata.traits)
        }
    }, [tokenMetadata])

    const traitSelected = (traitIndex) => {
        setSelectedTraitIdx(traitIndex);
        setSelectedTraitIdxDetails(traitIndex);
    }

    return (
        tokenMetadata && tokenMetadata.traits &&
        <div key={`trait_${tokenMetadata.id}`} ref={containerRef} className={`trait-card-container traits-on ${state[5].tokenId === tokenMetadata.id ? 'selected' : ''}`} onClick={() => handleSelectCardTrait(tokenMetadata.id)}>
            <div className="trait-slide pt-4 pb-3 px-3 text-center"
                style={{ height: '100%' }}>
                <div className="close-btn" style={{ display: `${!selectedTraitIdx ? 'none' : 'block'}` }} onClick={(e) => { if (selectedTraitIdx !== null) { setSelectedTraitIdx(null) } }}>
                    <img src={closeX} alt="close" />
                </div>

                <div className={`trait-container ${selectedTraitIdx != null ? 'trait-selected' : ''}`}>
                    {traitsOfToken.map((trait, i) => (
                        <div key={'trait-' + i}
                            className={`trait`}
                            style={{ width: width / 6, height: width / 6, margin: width / 30 + 'px', backgroundImage: `url(${traitImages[trait.id] ? traitImages[trait.id] : PLACEHOLDER})`, backgroundPosition: 'centered', backgroundRepeat: 'no-repeat' }}
                            onClick={(e) => traitSelected(i)}></div>
                    ))}
                </div>
                {traitsOfToken.length > 0 && <div className={`trait-details ${selectedTraitIdx != null ? 'trait-selected' : ''}`}>
                    <div className={`trait`} style={{ width: '75px', height: '75px', backgroundImage: `url(${traitImages[traitsOfToken[selectedTraitIdxDetails].id] ? traitImages[traitsOfToken[selectedTraitIdxDetails].id] : PLACEHOLDER})`, backgroundPosition: 'centered', backgroundRepeat: 'no-repeat' }}></div>
                    <h5 className="trait-name">{traitsOfToken[selectedTraitIdxDetails].name}</h5>
                    <p className="mt-3 px-3">{traitsOfToken[selectedTraitIdxDetails].description}</p>
                </div>}
            </div>
        </div>
    )
}

const ForgePage = (props) => {
    const baseCid = config.BASE_CID;
    const layersBaseUrl = config.LAYERS_BASE_URL

    const { onboard, address, ethersProvider } = useContext(Web3Ctx);
    const [isConnected, setIsConnected] = useState(false);
    const [uploadCardAmount, setUploadCardAmount] = useState(0);
    const [eligibleCardAmount, setEligibleCardAmount] = useState(4);
    const [uploadCard, setUploadCard] = useState([null, null]);
    const [showButton, setShowButton] = useState(false);
    const [showConfirmForgeButton, setShowConfirmForgeButton] = useState(false);

    const [showForgeStepOne, setShowForgeStepOne] = useState(false);
    const [showForgeStepTwo, setShowForgeStepTwo] = useState(false);
    const [showCardHoldings, setShowCardHoldings] = useState(true);

    const [forgeCardStateOne, setForgeCardStateOne] = useState([{}, {}, {}, {}, {}, {}]);
    const [showLoader, setShowLoader] = useState(false);
    const [showInfoPopup, setShowInfoPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [cardArtwork, setCardArtwork] = useState('');
    const [cards, setCards] = useState([1234, 4321, 7278, 1919, 1235, 1236, 1237, 1238, 1239, 100, 101, 102]);
    const [ec, setEc] = useState(null);

    useEffect(() => {
        console.log('FORGE');
        const initContract = async () => {
            const contract = await getContract('EtherCards', ethersProvider).catch(e => console.log('err:', e));
            setEc(contract);
        }
        if (ethersProvider) {
            initContract();
        }
    }, [ethersProvider]);

    useEffect(() => {
        if (ec && address) {
            console.log('about to get tokens [ec,address]', ec, address);
            setIsConnected(true);
            getTokens();
        } else {
            setIsConnected(false);
        }
    }, [ec, address]);

    useEffect(() => {
        console.info('set upload card')
        const notNullable = uploadCard.filter(obj => obj !== null);
        setUploadCardAmount(notNullable.length);
        setShowButton(notNullable.length == 2);
    }, [uploadCard]);

    useEffect(() => {
        const notNullable = forgeCardStateOne.filter(obj => obj.tokenId && obj.tokenId !== null)
        setShowConfirmForgeButton(showForgeStepOne ? notNullable.length == 5 : notNullable.length == 6);
    }, [forgeCardStateOne, showForgeStepOne, showForgeStepTwo])

    const getTokens = async () => {
        if (!ec) {
            toast.error('Contract not found');
            return;
        }
        setIsLoading(true);
        const numberOfTokens = cards.length // await ec.balanceOf(address); //BigNumber
        if (cards.length > 0) {
            let metadata = [];
            for (let i = 0; i < numberOfTokens; i++) {
                const tokenId = cards[i] // await ec.tokenOfOwnerByIndex(address, i);
                const meta = await getTokenURI(resolveTokenJsonUri(tokenId));
                metadata.push(meta);
            }
            setCards(metadata.sortBy('id'));
            setEligibleCardAmount(metadata.length)
        } else {
            setCards([]);
        }
        setIsLoading(false);
    }

    const showImage = (imgUrl) => {
        setCardArtwork(imgUrl);
    }

    const uploadCardRef = useRef()
    const { width, height } = useContainerDimensions(uploadCardRef)
    const getItemStyle = (isDragging) => {
        if (isDragging) {
            return {
                height: height,
                width: width
            }
        }
    };
    const getDroppableStyle = (isDraggingOver) => {
        return {
            // change background colour if dragging
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: isDraggingOver ? "#8000807d" : "#161920",
            opacity: isDraggingOver ? "0.4" : "1",
            height: '100%',
            width: width,
            border: '2px dashed purple',
            borderRadius: '20px',
        }
    };

    const getForgeContainerStyle = () => {
        return {
            backgroundColor: !showCardHoldings ? '#131519' : "#161920"
        }
    }

    const onDragEnd = (result) => {
        const { source, destination, draggableId } = result;
        if (!result.destination) {
            return;
        }
        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) {
            return;
        }

        const holdingCards = [...cards];
        const newUploadCards = [...uploadCard];
        const cardMetadata = cards.find(card => `${card.id}` === `${draggableId}`);
        if (destination.droppableId === 'uploadCardOne') {
            holdingCards.splice(source.index, 1);
            setCards(holdingCards);
            newUploadCards.splice(0, 1, cardMetadata);
            if (uploadCard[0]) {
                holdingCards.splice(0, 0, uploadCard[0])
            }
            setUploadCard(newUploadCards);
            setCards(holdingCards)
        } else if (destination.droppableId === 'uploadCardTwo') {
            holdingCards.splice(source.index, 1);
            setCards(holdingCards);
            newUploadCards.splice(1, 1, cardMetadata);
            if (uploadCard[1]) {
                holdingCards.splice(0, 0, uploadCard[1])
            }
            setUploadCard(newUploadCards);
            setCards(holdingCards)
        } else {
            const reorderedCards = reorder(
                cards,
                source.index,
                destination.index
            );
            setCards(reorderedCards);
        }
    }

    // a little function to help us with reordering the result
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getStyle = (style, snapshot) => {
        if (!snapshot.isDropAnimating) {
            return style;
        }
        const { moveTo, curve, duration } = snapshot.dropAnimation;
        // move to the right spot
        let translate = ''
        if (snapshot.draggingOver === 'uploadCardOne' || snapshot.draggingOver === 'uploadCardTwo') {
            //translate = `translate(${moveTo.x-69}px, ${moveTo.y-365}px)`;
        }
        // add a bit of turn for fun
        const rotate = 'rotate(0.5turn)';
        // patching the existing style
        let newStyle = {};
        if (translate !== '') {
            //newStyle.transform = translate;
        }
        return {
            ...style,
            ...newStyle,
            //transform: `${translate} ${rotate}`,
            // slowing down the drop because we can
            paddingLeft: 0,
            transition: `all ${curve} ${duration}s`,
        };
    }

    const handleRemoveCard = (index, tokenMetadata) => {
        if (showCardHoldings) {
            const holdingCards = [...cards];
            const newUploadCards = [...uploadCard];
            holdingCards.splice(0, 0, tokenMetadata);
            newUploadCards[index] = null;
            setCards(holdingCards);
            setUploadCard(newUploadCards);
        }
    }

    const handleSelectLayer = (tokenId, index) => {
        const newStateOne = [...forgeCardStateOne];
        newStateOne[index].tokenId = tokenId;
        setForgeCardStateOne(newStateOne)
    }

    const handleSelectCardTrait = (tokenId) => {
        const newStateOne = [...forgeCardStateOne];
        newStateOne[5].tokenId = tokenId;
        setForgeCardStateOne(newStateOne)
    }

    const forgeNow = () => {
        if (showCardHoldings) {
            setShowCardHoldings(false);
            setShowForgeStepOne(true);
        } else {
            // reset all
            const holdingCards = [...cards];
            holdingCards.push(uploadCard[0], uploadCard[1]);
            setCards(holdingCards);
            setUploadCard([null, null]);
            setShowCardHoldings(true);
            setShowForgeStepOne(false);
            setShowForgeStepTwo(false);
            setForgeCardStateOne([{}, {}, {}, {}, {}, {}]);
        }
    }

    const forgeStepTwo = () => {
        setShowForgeStepOne(false);
        setShowForgeStepTwo(true);
    }

    const forgeNowConfirm = () => {
        console.info('Start Forging')
        setShowLoader(true)
        const timer = setTimeout(() => {
            setShowLoader(false)
            setUploadCard([null, null]);
            setEligibleCardAmount(cards.length)
            setShowCardHoldings(true);
            setShowForgeStepTwo(false);
            setShowForgeStepOne(false);
            setForgeCardStateOne([{}, {}, {}, {}, {}, {}]);
        }, 3000);
    }

    const resolveCardType = (tokenId) => {
        return tokenId < 10 ? 4 : tokenId < 100 ? 0 : tokenId < 1000 ? 1 : 3
    }
    const resolveTokenJsonUri = (tokenId) => {
        return baseCid + '/' + tokenId % 100 + '/' + tokenId + '.json'
    }

    const renderEligibleAmount = () => {
        return (
            <div className="row" style={{ marginTop: 'auto' }}>
                <p className="col-5 offset-1 text-white text-right" style={{ fontWeight: 400 }}>
                    Eligible cards in your wallet <span>{eligibleCardAmount}</span>
                </p>
                                        |
                <p className="col-5 text-left" style={{ fontWeight: 400 }}>
                    All cards in your wallet <span>{eligibleCardAmount}</span>
                </p>
            </div>)
    }

    const renderConnectButton = () => {
        return (
            <div className="row">
                <div className="col-md-4 mx-auto mt-5 text-center">
                    <h5>In order to see your tokens, you need to connect your wallet</h5>
                    <button className="btn btn-peach btn-outline round mx-auto mt-5 px-4" onClick={() => { onboard.walletSelect() }}>CONNECT</button>
                </div>
            </div>
        )
    }

    const renderInfoPopup = () => {
        return (
            <Popup
                trigger={<img id="infoBtn" src={icInfo}></img>}
                position="right top"
                on="click">
                {close =>
                    <div class="info-popup">
                        <h3 class="mb-4">Forge Cards</h3>
                        <p class="mb-4">You need at least two Founder Cards of different subseries. Simply
                        select and drag & drop them into the placeholders on the top. Then hit
                        Forge Now button. The Forged card will be placed into your wallet.
                                        </p>
                        <button class="btn btn-peach btn-outline round text-white mt-2 px-4">READ MORE</button>
                        <img class="info-close-button" src={icClose} onClick={() => close()}></img>
                    </div>
                }
            </Popup>
        )
    }

    const renderDroppableUploadCard = (droppableId, tokenMetadata, handleRemoveCard) => {
        return (
            <Droppable droppableId={droppableId}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        style={getDroppableStyle(snapshot.isDraggingOver)}
                        ref={provided.innerRef}
                    >
                        {
                            tokenMetadata ?
                                <>
                                    <NftCardV2
                                        demoMode="true"
                                        tokenJson={tokenMetadata}
                                        cardType={resolveCardType(tokenMetadata.id)}
                                        tokenImage={null}
                                        showImage={showImage}
                                    />
                                    {showCardHoldings ?
                                        <div className="overlay-forging" >
                                            <div className="remove-button" onClick={() => handleRemoveCard()}>
                                                <img height={30} src={icClose}></img>
                                            </div>
                                        </div>
                                        :
                                        <div className="overlay-forging">
                                            <h4>Founder #{tokenMetadata && tokenMetadata.id}</h4>
                                        </div>
                                    }
                                </>
                                :
                                <>
                                    <div className='plus-button'>
                                        <img height={40} src={icDnd}></img>
                                    </div>
                                    <div className='bottom-text'>
                                        Drag & Drop your card here
                                    </div>
                                </>
                        }
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        )
    }

    const renderSelectableLayers = (tokenMetadata) => {
        return (
            tokenMetadata && getLayersURI(tokenMetadata.layer_image, tokenMetadata.layer_artists).map((layer, i) => {
                const tokenId = tokenMetadata.id;
                return (
                    <div key={`layer_${tokenId}_${i}`} className={`layer-card-container ${forgeCardStateOne[i].tokenId === tokenId ? 'selected' : ''}`} onClick={() => handleSelectLayer(tokenId, i)}>
                        <img src={`${layersBaseUrl}/${layer.url}`}>
                        </img>
                        <div className="layer-name">{`${i + 1}. ${layer.artist}`}</div>
                    </div>
                );
            })
        )
    }

    return (
        <div id="forgeContainer" style={getForgeContainerStyle()}>
            <Navigation />
            <DragDropContext onDragEnd={onDragEnd}>
                <section id="forgeSection">
                    <div className="container mt-3 pt-3 text-center d-flex flex-column">
                        <h3 className="mb-3">The Forge {renderInfoPopup()}
                        </h3>
                        {
                            showButton ?
                                <button
                                    id="forgeNowBtn"
                                    className="btn btn-peach btn-outline round mr-auto ml-auto px-4 text-white"
                                    onClick={() => { forgeNow() }}>{`${showCardHoldings ? 'FORGE NOW' : 'RESET'}`}</button>
                                :
                                <div>Uploaded Card <span className="text-white">{uploadCardAmount}/2</span></div>

                        }
                        <div className="row" style={{ height: '80%', padding: '20px' }}>
                            <div className="col-6 col-md-4 col-lg-3 col-xl-3 offset-md-2 offset-lg-3 offset-xl-3">
                                <div className="upload-card-container" ref={uploadCardRef}>
                                    {renderDroppableUploadCard('uploadCardOne', uploadCard[0], () => handleRemoveCard(0, uploadCard[0]))}
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-3 col-xl-3">
                                <div className="upload-card-container">
                                    {renderDroppableUploadCard('uploadCardTwo', uploadCard[1], () => handleRemoveCard(1, uploadCard[1]))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Holdings Section */}
                <section id="cardsSection" className={`d-flex flex-column ${!showCardHoldings && 'hide'}`}>
                    {isConnected ?
                        <div className="container mt-3">
                            {isLoading ?
                                <div className="col-lg-12 mt-5 mb-5 text-center">
                                    <SpinnerDotted enabled={isLoading} size={35} thickness={160} speed={200} color="#fff" />
                                </div>
                                :
                                <>
                                    {renderEligibleAmount()}
                                    <Droppable droppableId="cards" direction="horizontal">
                                        {(provided) => (
                                            <div className="row mx-auto mt-4" {...provided.droppableProps} ref={provided.innerRef}>
                                                {cards.map((metadata, i) => (
                                                    <Draggable
                                                        key={`draggable_${metadata.id}_$`}
                                                        draggableId={`${metadata.id}`}
                                                        index={i} >
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <div
                                                                    className="col-6 col-sm-6 col-md-4 col-lg-3 mb-3"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={getStyle(provided.draggableProps.style, snapshot)}
                                                                    key={`card_${metadata.id}`}>
                                                                    <div style={getItemStyle(snapshot.isDragging)}>
                                                                        <NftCardV2
                                                                            demoMode="true"
                                                                            tokenJson={metadata}
                                                                            cardType={resolveCardType(metadata.id)}
                                                                            tokenImage={null}
                                                                            showImage={showImage}
                                                                        />
                                                                        <div className='card-text'>
                                                                            Card No.: <span style={{ fontWeight: '400', color: 'white' }}>{metadata.id}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </>
                            }
                        </div>
                        :
                        renderConnectButton()
                    }
                    {cards.length === 0 &&
                        <div className="col-md-6 mx-auto mt-5 text-center">
                            <h5>Your wallet is empty.</h5>
                        </div>
                    }
                </section>
            </DragDropContext>

            <section id="layerSection" className={`d-flex flex-column ${!showForgeStepOne && 'hide'}`}>
                <div className="container">
                    <div className="row text-center" style={{ height: '80%', padding: '0 20px 0 20px' }}>
                        <div className="col-6 col-md-4 col-lg-3 offset-md-2 offset-lg-3">
                            {renderSelectableLayers(uploadCard[0])}
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            {renderSelectableLayers(uploadCard[1])}
                        </div>
                    </div>
                    <div className="row" style={{ padding: '20px' }}>
                        <div className="col-8 col-md-6 offset-2 offset-md-3 text-center">
                            <h4>Preview</h4>
                            <div className="merge-card-container">
                                {
                                    forgeCardStateOne.map((state, i) => {
                                        if (state.tokenId && i < 5) {
                                            let cardMetadata = uploadCard.find(card => card && `${card.id}` === `${state.tokenId}`);
                                            if (cardMetadata) {
                                                let layersUri = getLayersURI(cardMetadata.layer_image, cardMetadata.layer_artists);
                                                let layer = layersUri[i];
                                                let src = `${layersBaseUrl}/` + layer.url;
                                                return (<img key={`merged_layer_${i}`} src={src}></img>);
                                            }
                                        }
                                    })
                                }
                                <div className="ec-logo" style={{ width: '80%', marginLeft: "10%" }}>
                                    <img src={ecLogo} alt="ethercards" />
                                </div>
                            </div>
                            {
                                showConfirmForgeButton &&
                                <button
                                    id="forgeNowBtn"
                                    className="btn btn-peach round btn-outline mr-auto ml-auto px-4 text-white"
                                    onClick={(e) => { forgeStepTwo() }}>CONFIRM FORGE 1/2</button>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section id="traitsSection" className={`d-flex flex-column ${!showForgeStepTwo && 'hide'}`}>
                <div className="container text-center">
                    <h4>Choose your traits</h4>
                    <div className="row text-center" style={{ height: '80%', padding: '0 20px 0 20px' }}>
                        <div className="col-6 col-md-4 col-lg-3 offset-md-2 offset-lg-3">
                            {
                                showForgeStepTwo &&
                                <CardTraits tokenMetadata={uploadCard[0]} state={forgeCardStateOne} handleSelectCardTrait={handleSelectCardTrait} />
                            }
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            {
                                showForgeStepTwo &&
                                <CardTraits tokenMetadata={uploadCard[1]} state={forgeCardStateOne} handleSelectCardTrait={handleSelectCardTrait} />
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {
                                showConfirmForgeButton &&
                                <button
                                    id="forgeNowBtn"
                                    className="btn btn-peach round btn-outline mr-auto ml-auto px-3 text-white"
                                    onClick={(e) => { forgeNowConfirm() }}>CONFIRM FORGE 2/2</button>
                            }
                        </div>
                    </div>
                </div>
            </section>

            <ImageView src={cardArtwork} onClose={() => setCardArtwork('')} />
            {
                showLoader &&
                <div className="forge-loader">
                    <div>
                        <SpinnerDotted enabled={true} size={35} thickness={160} speed={200} color="#fff" />
                        <h4 className="mt-2">Forging...</h4>
                    </div>
                </div>
            }
            <div style={{ marginTop: 'auto' }}>
                <div className="container pt-4">
                    <FooterComponent />
                </div>
            </div>
        </div >
    )
}

export default ForgePage;
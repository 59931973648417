import React, { useContext, useEffect, useState } from 'react';
import Navigation from '../Navigation/Navigation';
import './WalletPage.css';
import Web3Ctx from '../Context/Web3Ctx';
import { getContract } from '../Utils/GetContract';
import { toast } from 'react-toast';
import FooterComponent from '../Footer/FooterComponent';
import ImageView from '../common/ImageView';
import { SpinnerDotted } from 'spinners-react';
import NftCardV2 from '../common/NftCardV2';
import config from '../../config'

const WalletPage = (props) => {
    const { onboard, address, ethersProvider } = useContext(Web3Ctx);
    const [isConnected, setIsConnected] = useState(false);
    const [connectedAddress, setIsConnectedAddress] = useState('0x0');
    const [ec, setEc] = useState(null);
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [cardArtwork, setCardArtwork] = useState('');

    const baseCid = config.BASE_CID;

    useEffect(() => {
        console.log('WALLET');

        const initContract = async () => {
            const contract = await getContract('EtherCards', ethersProvider).catch(e => console.log('err:', e));
            setEc(contract);
        }

        if (ethersProvider) {
            initContract();
        }
    }, [ethersProvider]);

    useEffect(() => {
        if (ec && address) {
            //console.log('about to get tokens [ec,address]',ec,address);
            setIsConnected(true);
            getTokens();
        } else {
            setIsConnected(false);
        }
    }, [ec, address]);

    const getTokens = async () => {
        if (!ec) {
            toast.error('Contract not found');
            return;
        }
        setIsLoading(true);
        const numberOfTokens = await ec.balanceOf(address);
        //console.log(numberOfTokens.toNumber());
        if (numberOfTokens.toNumber() > 0) {
            let tokenIds = [];
            for (let i = 0; i < numberOfTokens; i++) {
                const tokenId = await ec.tokenOfOwnerByIndex(address, i);
                /*  const cardType = await ec.cardType(tokenId);
                 const tokenUri = await ec.tokenURI(tokenId); */
                // console.log('cardtype, tokenurei',cardType,tokenUri);
                tokenIds.push(tokenId);
            }
            //console.log('tokenIds',tokenIds);
            setCards(tokenIds);
        } else {
            setCards([]);
        }
        setIsLoading(false);
    }

    const showImage = (imgUrl) => {
        setCardArtwork(imgUrl);
        //console.log('view image fn',imgUrl);
    }

    return (
        <>
            <Navigation />
            <div id="walletContainer" className="container">
                <div className="row padd">
                    <div className="col-lg-12 mt-5">

                        <h2 className="text-center text-peach">Welcome to your wallet.</h2>

                        <div className="row">
                            <div className="col-md-8 mx-auto text-left mt-5 mb-3">

                                <p>Just like a cryptocurrency wallet, it will allow you to receive and store your first Ether Cards.</p>

                                <p>In the future, you'll also be able to use this wallet to send, receive and trade Ether Cards with other community members. For now, it will serve as a repository for your collection and as your own personal gallery, where you can view the Cards' art.</p>

                                <p>You can find your wallet address in the top right corner of this page.</p>
                            </div>
                        </div>


                        {isConnected ?
                            <div className="row">

                                {isLoading ?
                                    <div className="col-lg-12 mt-5 mb-5 text-center">
                                        <SpinnerDotted enabled={isLoading} size={35} thickness={160} speed={200} color="#fff" />
                                    </div>
                                    :
                                    <>
                                        {cards.map((key, i) => {
                                            return (
                                                <div key={cards[i]} className="col-md-3 mx-auto mt-5">
                                                    <NftCardV2
                                                        demoMode="true" 
                                                        tokenJsonUri={baseCid + '/' + cards[i] % 100 + '/' + cards[i] + '.json'}
                                                        cardType={cards[i] < 10 ? 4 : cards[i] < 100 ? 0 : cards[i] < 1000 ? 1 : 3}
                                                        tokenImage={null}
                                                        showImage={showImage}
                                                    />
                                                </div>
                                            );
                                        })}

                                        {cards.length === 0 &&
                                            <div className="col-md-6 mx-auto mt-5 text-center">
                                                <h5>Your wallet is empty.</h5>
                                            </div>
                                        }
                                    </>
                                }
                            </div>

                            :

                            <div className="row">
                                <div className="col-md-4 mx-auto mt-5 text-center">
                                    <h5>In order to see your tokens, you need to connect your wallet</h5>

                                    <button className="btn btn-peach btn-outline round mx-auto mt-5 px-3" onClick={() => { onboard.walletSelect() }}>CONNECT</button>
                                </div>
                            </div>
                        }
                    </div>

                </div>
                <div className="mt-5">
                    <FooterComponent />
                </div>
            </div>
            <ImageView src={cardArtwork} onClose={() => setCardArtwork(null)} />
        </>
    );
}

export default WalletPage;
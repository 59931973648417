import React, { useEffect, useState } from 'react';
import './ImageView.css';

import closeX from '../../assets/images/nft-icons/close_button.svg';
import RemoteImage from './RemoteImage';
import LayeredImageViewer from './LayeredImageViewer';

const ImageView = (props) => {

    const [cardArtwork,setCardArtwork] = useState({media:''});

    useEffect(()=>{
        if(props.src && props.src.media !== cardArtwork.media){
            //console.log('SRC',props.src.media)
            setCardArtwork(props.src);
           // console.log('image view', props.src);
        }

    },[props]);

    const closeClicked=()=>{
       // console.log('close img')
        if(props.onClose){
            setCardArtwork({type:'image',media:''})
            props.onClose();
        }
    }
 
    return (
        <div className={`image-view ${cardArtwork.media?'show':''}`} onClick={closeClicked}>
                <div className='close-btn' onClick={closeClicked}>
                    <img src={closeX} />
                </div>
                <div className="row h-100 d-flex align-items-center">
                    {cardArtwork.type==='video'?
                        <div className="col-md-12 mx-auto " dangerouslySetInnerHTML={
                            { __html: `
                                        <video
                                        controls
                                        loop
                                        muted
                                        autoplay
                                        playsinline
                                        src="${cardArtwork.media}"
                                        class="${'w-75 view-card-video'}"
                                        />`}}>
                        </div>
                        :
                        <>
                            {cardArtwork.type==='image'?
                                <div className="w-100 h-100 p-5">
                                    <RemoteImage src={cardArtwork.media} disableBg={true}/>
                                </div>
                                :
                                <div className="w-100 h-100 p-5">
                                    <LayeredImageViewer src={cardArtwork.media} />
                                </div>
                            }
                        </>
                    }
                </div>
        </div>
    )
}

export default ImageView;
import React, { useContext, useEffect, useState } from 'react';
import NftCardV2 from '../common/NftCardV2';
import Web3Ctx from '../Context/Web3Ctx';
import { getTokenURI, getLayersURI } from '../Utils';
import { getContract } from '../Utils/GetContract';
import config from '../../config'

import './Explorer.css';
import opensea from '../../assets/images/nft-icons/icon_opensea2.png';
import etherscan from '../../assets/images/nft-icons/icon_etherscan2.png';
import closeX from '../../assets/images/nft-icons/close_button.svg';
import Address from '../common/Address';
import ImageView from '../common/ImageView';

import combined from '../../assets/metas.json';

import layerCount from '../../assets/layerCount.json';

const Explorer = (props) => {
    const {ethersProvider} = useContext(Web3Ctx);

    const [isVisible,setIsVisible] = useState(false);

    const [tokenId, setTokenId] = useState(null);
    const [tokenMeta, setTokenMeta] = useState(null);
    const [serieName, setSerieName] = useState('');

    const [baseCid,setBaseCid] = useState ('');
    const [cardArtwork,setCardArtwork] = useState('');
    const [metaData,setMetaData] = useState(null);
    const [owner,setOwner] = useState(null);

    const [contract,setContract] = useState(null);
    const [layerUrls,setLayerUrls] = useState([]);
    const [isStacked,setIsStacked] = useState(true);
    const [illegalId,setIllegalId] = useState(false);
    const [isExplorerActive,setIsExplorerActive] = useState(false);
    const [areLittleOnesVisible,setAreLittleOnesVisible] = useState(false);
    
    
    const layersBaseUrl = config.LAYERS_BASE_URL
    const series = {
        founder: {
            contractAddress: '0x000',
            baseCid: config.BASE_CID
        }
    }

    useEffect(()=>{
        const initContract = async () =>{
            let c = await getContract('EtherCards',ethersProvider);
            setContract(c);
        }

        if(ethersProvider){
            initContract();
        }

    },[ethersProvider]);
    
    useEffect(()=>{
        if(props.visible && props.visible!==isVisible){
            setIsVisible(props.visible);
        }

        if(props.serie!==serieName){
            setSerieName(props.serie);
        }


        if(series[props.serie] && contract && tokenId != props.tokenId ){
            console.log('series found:',props.serie, props.tokenId);
            setBaseCid(series[props.serie].baseCid);
            setTokenId(props.tokenId);

            getMeta(series[props.serie].baseCid, props.tokenId);
        }


    },[props, contract]);

    
    const getMeta = async (b,t) => {
        let m = await getTokenURI(b+'/'+t%100+'/'+t+'.json');
        console.log('tokenMeta', m)
        setTokenMeta(m);
        if(contract){
            let oa = await contract.ownerOf(t).catch(e=>console.log(e));
            console.log('id,add',t,oa);
            setOwner(oa? oa : '0x000000');
        }
        setupLayerExplorer(m);
    }

    const setupLayerExplorer = (meta) => {
        let url = meta.layer_image;
        let layerArtists = meta.layer_artists
        let luri = getLayersURI(url, layerArtists)
        console.log(luri);
        setLayerUrls(luri);
    }

    const showImage = (imgUrl) => {
        setCardArtwork(imgUrl);
       // console.log('view image fn',imgUrl);
    }

    const getIndexFromId = (id) =>{
        for(let i=0; i<combined.length;i++){
            if(combined[i].id === id){
                return i;
            }
        }
        return null;
    }


    const handlePrev = () => {
        

        if(props.onNavigateClick && typeof props.onNavigateClick ==='function'){

            let idx = getIndexFromId(tokenId);


            if(idx !==null && idx >0){
                props.onNavigateClick('/founder/'+(combined[idx-1].id));
               // props.history.push('/founder/'+(tokenId-1));//stupid js....
                
            }
        }
        
    }
    const handleNext = () => {
        if(props.onNavigateClick && typeof props.onNavigateClick ==='function'){
            let idx = getIndexFromId(tokenId);
            if(idx !==null &&  idx<combined.length-1){
                props.onNavigateClick('/founder/'+(combined[idx+1].id));
               // props.history.push('/founder/'+(tokenId+1));
            }
        }
    }



    return(
        <>
        <div className="explorer-mask">
            {tokenMeta &&
            <div className="explorer-container container w-75">
                <div className="row">
                    <div className="col-md-4 offset-md-3 pr-md-5 mb-5">
                    <>
                        <div style={{display:isExplorerActive?'none':'block'}}>

                            <NftCardV2 
                                
                                demoMode="true" 
                                tokenJsonUri = {baseCid+'/'+tokenId%100+'/'+tokenId+'.json'}
                                cardType={tokenId<10?4:tokenId<100?0:tokenId<1000?1:3}
                                tokenImage={null}
                                showImage={showImage}
                                exploreTheLayers={()=>{setIsExplorerActive(true);setAreLittleOnesVisible(false)}}
                            />
                        </div>
                            
                        {isExplorerActive&&
                            <>
                            <div className={`row accidental-art stacked`} onClick={()=>{setIsExplorerActive(false);setAreLittleOnesVisible(true)}} >

                                {layerUrls.map((k,i)=>{return(
                                    <div key={'l'+i} className={`layer-container mx-auto`}>
                                        <img className="w-100" src={layersBaseUrl+layerUrls[i].url}/>
                                        <div className="layer-info mt-2">
                                            <h5 className="mb-3 text-center"><span className={`${isStacked?'d-block':'d-none'}`}>{layerUrls[i].artist}</span></h5>
                                        </div>
                                    </div>
                                )})}
                                <div className="collapse-button" onClick={(e)=>{e.stopPropagation(); setIsStacked(true);}}>COLLAPSE</div>
                            </div>

                            
                            </>
                        }
                            
                    </>


                    </div>
                    <div className="col-md-3 description mb-5">
                        <h2 className="mb-1">{tokenMeta.title}</h2>
                        <h2>#{tokenMeta.id}</h2>

                        <div className="lower-part mt-5">
                            <a href={`https://opensea.io/assets/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8/${tokenMeta.id}`} target="_blank" className="lnk opensea">
                                <img src={opensea} alt="opensea link"/>
                            </a>
                            <a href={`https://etherscan.io/token/0x97ca7fe0b0288f5eb85f386fed876618fb9b8ab8?a=${tokenMeta.id}`} target="_blank" className="lnk etherscan">
                                <img src={etherscan} alt="etherscan link"/>
                            </a>

                            <p className="mt-3 mb-1">Artist: <span className="text-white">{tokenMeta.artist}</span></p>
                            <p className="mb-1">Owner: <span className="text-white"><Address address={owner} short blockie scale={4}/></span></p>
                            <p className="mb-1">Other side: <span className="text-white">{tokenMeta.image===tokenMeta.layer_image?'No':'Yes'}</span></p>
                            <p className="mb-0">Layers: <span className="text-white">{tokenMeta.layer_artists.join(', ')}</span></p>
                            
                        </div>
                    </div>

                    <button onClick={handlePrev} className="btn btn-peach btn-outline btn-prev">{'<'}</button>
                    <button onClick={handleNext} className="btn btn-peach btn-outline btn-next">{'>'}</button>
                </div>

                {areLittleOnesVisible &&
                    
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <div className="close smaller" onClick={()=>{setAreLittleOnesVisible(false)}}>
                                <img src={closeX} alt=""/>
                            </div>
                            <div className="row layers-in-row pb-5">
                                        {layerUrls.map((k,i)=>{return(
                                        <div key={'l-r'+i} className="col-md-2 px-0 mx-auto layer-c mb-3">
                                            <img className="w-100" src={layersBaseUrl+layerUrls[i].url}/>
                                            <div className="layer-details">
                                                <p className="mb-0"><span className="text-white">{layerUrls[i].artist}</span></p>
                                                <p className="mb-0"><span className="">{layerUrls[i].count}</span></p>
                                                <p className="mb-0"><span className="">{layerUrls[i].rarity}</span></p>
                                            </div>
                                        </div>
                                    )})}
                            </div>
                        </div>
                    </div>
                    }
                
                <div className="close" onClick={props.onClose}>
                    <img src={closeX} alt=""/>
                </div>
            </div>
            }


        
        </div>



        <ImageView src={cardArtwork} onClose={()=>setCardArtwork(null)}/>

        </>
    );
}


export default Explorer;
import { useEffect, useState } from 'react';
import './LayeredImageViewer.css';



const LayeredImageViewer = (props) => {
    const [imageSrc, setImageSrc] = useState('');
    const [layerUrls, setLayerUrls] = useState([]);
    const layersBaseUrl = 'https://ether-cards.mypinata.cloud/ipfs/Qmcm7BjsmhwWVA611EZSGkxcqt3JmsbF9m37kPNhDLoy4o';
   

    const [mouseX,setMouseX] = useState(0);
    const [mouseY,setMouseY] = useState(0);
    const [multiplier,setMultiplier] = useState(0);

    const [orig,setOrig] = useState({x:0,y:0});


    useEffect(()=>{

        console.log('mount',window);
        window.addEventListener('devicemotion', handleAcceleration);
       

        return ()=>{
            console.log('dismount');
            window.removeEventListener('devicemotion', handleAcceleration);
        }
    },[]);

    useEffect(()=>{
        if(props.src && props.src!==imageSrc){
            setImageSrc(props.src);
            setupLayers(props.src);
        }

        if(props.embedded && props.embedded === true){
            setMultiplier(1)
        }else{
            setMultiplier(0);
        }

    },[props])
    
    const setupLayers = (url)=>{
        let fileName=url.substring(url.lastIndexOf('/') + 1,url.lastIndexOf('.'));
        console.log('filename',fileName);

        if(fileName.length!==10){
            console.log('wrong file name:',fileName);
            return;
        }
        let luri = [];

        for(let i=0; i<5;i++){
            if(fileName[2*i]==='0'){
                luri.push(layersBaseUrl+'/common_layers/'+(i+1)+'/'+fileName[2*i+1]+'.png');
            }else{
                luri.push(layersBaseUrl+'/layers/'+fileName[2*i]+'/'+fileName[2*i+1]+'.png');
            }
       }

       console.log(luri);
       setLayerUrls(luri);

    }

    const handleMouseMove = (e)=>{


        
        let w=e.target.clientWidth;
        let h=e.target.clientHeight;


   /*      let x=e.clientX;
        let y=e.clientY;
 */
        let x=e.nativeEvent.offsetX;
        let y=e.nativeEvent.offsetY;


       //console.log(e.nativeEvent.offsetX);
        setMouseX(x-(w/2));
        setMouseY(y-(h/2));
    }

    const handleAcceleration = (e) => {

   
     //   console.log('accel',e);
        setMouseX((e.accelerationIncludingGravity.x)*50);
        setMouseY((e.accelerationIncludingGravity.y)*50);

    }


    return (
        <div className="fs-layer-container" onMouseMove={handleMouseMove} >
            {layerUrls.map((k,i)=>{return(
                
                <img key={'k'+i}   src={layerUrls[i]} 
                        style={{transform:
                                `
                                 translateX(${(mouseX/-25)*(i*0.7+1)}px) 
                                 translateY(${(mouseY/-25)*(i*0.7+1)}px)
                                 scale(${1+(0.04*multiplier)*(i===0?1:0)})
                                 `}}/>
            
            )})}

        </div>
    );

}

export default LayeredImageViewer;
import RubberSpider from './components/gumipok/RubberSpider';
import Home from './components/Home/Home';
import ForgePage from './components/Forge/ForgePage';
import WalletPage from './components/Wallet/WalletPage';

const routes = [
     {
          'path': '/',
          'component': Home,
          'exact': true
     },
     {
          'path': '/:series/:tokenId',
          'component': Home,
          'exact': true
     },
     {
          'path': '/embed/:series/:tokenId',
          'component': RubberSpider,
          'exact': true
     }, 
     {
          'path': '/forge',
          'component': ForgePage,
          'exact': true
     },
     {
          'path': '/wallet',
          'component': WalletPage,
          'exact': true
     },
];

export default routes;

